import { Button, ResourceComponentProps, useRecordContext } from 'react-admin';
import { httpClient } from '@/App';
import { useCallback } from 'react';
import { OrganizationDetails } from '@/types';

export const TenantDbSyncCheckButton = (
  props: ResourceComponentProps & {
    resource: string;
    title?: string;
  },
) => {
  const { resource, title, ...rest } = props;
  const organizationDetails = useRecordContext<OrganizationDetails>(props);

  const validateDBRecords = useCallback(async () => {
    if (!organizationDetails?.tenantDbHost) {
      return;
    }

    try {
      const res = (await httpClient(
        `${process.env.REACT_APP_API_BASE_URL ?? ''}/organizations/${
          organizationDetails.id
        }/tenant/validation`,
        {
          method: 'POST',
          body: JSON.stringify({
            tenantDbHost: organizationDetails.tenantDbHost,
          }),
        },
      )) as {
        json: {
          success: boolean;
        };
      };

      if (res.json.success) {
        // eslint-disable-next-line no-alert
        window.alert('DB Records are valid');
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert((e as Error).message);
    }
  }, [organizationDetails]);

  const validateDBRecordsMD5 = useCallback(async () => {
    if (!organizationDetails?.tenantDbHost) {
      return;
    }

    try {
      const res = (await httpClient(
        `${process.env.REACT_APP_API_BASE_URL ?? ''}/organizations/${
          organizationDetails.id
        }/tenant/validation-md5`,
        {
          method: 'POST',
          body: JSON.stringify({
            tenantDbHost: organizationDetails.tenantDbHost,
          }),
        },
      )) as {
        json: {
          success: boolean;
        };
      };

      if (res.json.success) {
        // eslint-disable-next-line no-alert
        window.alert('DB Records are valid');
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert((e as Error).message);
    }
  }, [organizationDetails]);

  return (
    <>
      <Button
        disabled={!organizationDetails?.tenantDbHost}
        label={title ?? 'Validate Tenant DB'}
        title={title ?? 'Validate Tenant DB'}
        {...rest}
        variant="contained"
        onClick={validateDBRecords}
      />
      <Button
        disabled={!organizationDetails?.tenantDbHost}
        label={title ?? 'Validate Tenant DB MD5'}
        title={title ?? 'Validate Tenant DB MD5'}
        {...rest}
        variant="contained"
        onClick={validateDBRecordsMD5}
      />
    </>
  );
};
