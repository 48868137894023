import { Link } from 'react-router-dom';
import IconEdit from '@material-ui/icons/Edit';
import { Button, ResourceComponentProps, useRecordContext } from 'react-admin';

export const TenantDbHostSetupButton = (
  props: ResourceComponentProps & {
    resource: string;
    title?: string;
  },
) => {
  const { resource, title, ...rest } = props;
  const record = useRecordContext(props);
  const fullPath = `/${resource}/${record.id}/tenant/migration`;

  return (
    <Button
      style={{
        opacity: 0,
        // use dev tool in browser to disable this css property.
        pointerEvents: 'none',
      }}
      component={Link}
      to={fullPath}
      label={title ?? 'Switch Tenant DB'}
      title={title ?? 'Switch Tenant DB'}
      {...rest}
    >
      <IconEdit />
    </Button>
  );
};
