import React from 'react';
import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  useGetOne,
  Loading,
  TextField,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from '../common/TopToolbarWithBackButton';
import { OrganizationDetails } from '@/types';

export const TenantDbHostSetup: React.FC<
  CreateProps & {
    resource: string;
    basePath: string;
    redirect: string;
    source: string;
    title: string;
  }
> = (props) => {
  const { resource, basePath, redirect, source, title, ...rest } = props;
  const { resourceId } = useParams<{ resourceId: string }>();
  const redirectPath = `/${resource}/${resourceId}/${redirect}`;
  const { data: orgDetails, loading } = useGetOne<OrganizationDetails>(
    resource,
    resourceId,
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Create
      basePath={basePath}
      resource={`${resource}/${resourceId}/tenant`}
      actions={<TopToolbarWithBackButton path={redirectPath} />}
      title={`Setup ${resourceId} Isolated Tenant`}
      {...rest}
    >
      <SimpleForm redirect={redirectPath}>
        <TextField
          source="tenant"
          emptyText={`now set: ${orgDetails?.tenantDbHost ?? '--'}`}
        />
        <TextInput source="tenantDbHost" type="string" />
      </SimpleForm>
    </Create>
  );
};
