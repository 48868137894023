import * as React from 'react';
import { Menu as DefaultMenu, MenuItemLink, MenuProps } from 'react-admin';
import IconEvent from '@material-ui/icons/Event';
import IconGroup from '@material-ui/icons/Group';
import IconAssignment from '@material-ui/icons/Assignment';
import IconPerson from '@material-ui/icons/Person';
import IconDns from '@material-ui/icons/Dns';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import SettingIcon from '@material-ui/icons/Settings';

export const Menu: React.FC<MenuProps> = (props: MenuProps) => (
  <DefaultMenu {...props}>
    <MenuItemLink to="/events" primaryText="Events" leftIcon={<IconEvent />} />
    <MenuItemLink
      to="/organizations"
      primaryText="Organizations"
      leftIcon={<IconGroup />}
    />
    <MenuItemLink
      to="/release-controls"
      primaryText="Release Control"
      leftIcon={<IconAssignment />}
    />
    <MenuItemLink to="/users" primaryText="Users" leftIcon={<IconPerson />} />
    <MenuItemLink
      to="/impersonate-tokens"
      primaryText="Impersonate Tokens"
      leftIcon={<IconPerson />}
    />
    <MenuItemLink
      to="/custom-domains"
      primaryText="Custom Domains"
      leftIcon={<IconDns />}
    />
    <MenuItemLink
      to="/public-api-tokens"
      primaryText="Public Api Tokens"
      leftIcon={<VpnKeyIcon />}
    />
    {/* <MenuItemLink
      to="/system-config"
      primaryText="System Config"
      leftIcon={<SettingIcon />}
    /> */}
  </DefaultMenu>
);
